<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12">
            </div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint"
                            :params="state.params"
                            namefile="Detail-Target-Per-Toko-By-SPC"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)"
                                :disabled="record.status_id != 1">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            :roles="[ROLE_SPC]"
            only-disable
            @success="fetchDataList"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from './Form'
import _ from 'lodash'
import {
    hasRoles,
    ROLE_SPC,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        Form,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()
        const route = useRoute()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Kabupaten/Kota',
                    dataIndex: 'kabupaten',
                },
                {
                    title: 'Total Target',
                    dataIndex: 'total_demand',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: `/api/snop/target-penjualan-distributor/${route.params.id}/target-spc`,
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                page: 1,
                "per-page": 10,
            }),
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // Function to get unique brands from data
        const getUniqueBrands = (data, key) => {
            const brands = _.flatMap(data, item => item[key])
            return _.uniqBy(brands, 'brand_id')
        }

        // Generate columns dynamically
        const generateDynamicColumns = (data) => {
            if (_.some(state.columns, { dataIndex: 'demand_spc' })) {
                _.remove(state.columns, { dataIndex: 'demand_spc' });
            }

            let columns = []
            const spcBrands = getUniqueBrands(data, 'brands')

            columns.push({
                title: `Target Per Brand By SPC`,
                dataIndex: 'demand_spc',
                children: [
                    ...spcBrands.map(brand => ({
                        title: brand.brand_name,
                        dataIndex: `demand_spc_${brand.brand_id}`,
                    })),
                ],
            })

            return columns
        }

        const transformData = (data) => {
            return data.map(item => {
                // Process brands
                item.brands.forEach(brand => {
                    item[`demand_spc_${brand.brand_id}`] = brand.demand_value
                })
                return item
            })
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.columns.splice(6, 0, ...generateDynamicColumns(items))

                    state.data = transformData(items)
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle form
        const visibleFormModal = ref(false)
        const visibleFormItemModal = ref(null)

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            fetchDataList()

            // show column action only this roles
            if (!hasRoles([ROLE_SPC])) {
                state.columns = state.columns.filter(item => {
                    if (!_.includes(['action'], item.dataIndex)) {
                        return item
                    }
                })
            }
        })

        return {
            state,
            handleTableChange,
            fetchDataList,
            btnForm,
            visibleFormModal,
            visibleFormItemModal,
            // only variable role
            hasRoles,
            ROLE_SPC,
        }
    },
})
</script>
